<template>
    <div class="footer">
        <div class="navigator">
            <router-link class="active" to="/index">首页</router-link>
            <span></span>
            <router-link to="/product">产品服务</router-link>
            <span></span>
            <!-- <router-link to="/case">客服案例</router-link> -->
            <!-- <span></span> -->
            <router-link to="/news">新闻资讯</router-link>
            <span></span>
            <router-link to="/about">关于我们</router-link>
        </div>
        <div class="rights">
            ©2015-2022 融汇天下（北京）商务管理有限公司
            <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2021040381号-1</a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.footer {
    background: #27272a;
    width: 100%;
    padding: 27px 0 35px;
    margin-top: 50px;
    .navigator {
        text-align: center;
        margin-bottom: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            color: #aaaaaa;
            font-size: 18px;
            &.router-link-exact-active{
                color: #ffffff;
            }
        }
        span {
            width: 1px;
            height: 21px;
            background: #616161;
            margin: 0 15px;
        }
    }
    .rights {
        color: #aaaaaa;
        font-size: 12px;
        text-align: center;
        a{
            color: #aaaaaa;
            font-size: 12px;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}
</style>